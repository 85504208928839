import React from "react"
import window from "global"
import { Col} from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/SEO"
import Layout from "../components/layout"

function NotFoundPage() {
  const data = useStaticQuery(graphql`
    query notFoundPageQuery {
      BackgroundImage: file(relativePath: {eq: "CONTACT/LOSSE AFBEELDINGEN/Background_Contact.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SmallBackgroundImage: file(relativePath: {eq: "CONTACT/LOSSE AFBEELDINGEN/Background_Contact_Small.png"}) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const [hasMounted, setHasMounted] = React.useState(false)
  const [width, setWidth] = React.useState(window.innerWidth)
  const updateWidth = () => {
    setWidth(window.innerWidth)
  }

  React.useEffect(() => {
    window.addEventListener("resize", updateWidth)
    return () => window.removeEventListener("resize", updateWidth)
  }, [])

  React.useEffect(() => {
    setHasMounted(true)
  }, [width])

  if (!hasMounted) {
    return null
  }

  let backgroundImage = data.BackgroundImage

  let coverText = (
    <Col
      className={"head-text text-center"}
      style={{ color: "white", marginTop: "30vh" }}
      md={{ span: 10, offset: 1 }}>
      <h1 style={{ fontSize: "4em" }}>Error 404</h1>
      <h3>Helaas, pagina niet gevonden</h3>
    </Col>
  )
  if (width <= 1250) {
    coverText = (
      <Col
        className={"head-text text-center"}
        style={{ color: "white", marginTop: "-60vh", zIndex: -1 }}
        md={{ span: 10, offset: 1 }}>
        <h1 style={{ fontSize: "7vw" }}>Error 404</h1>
        <h3>Helaas, pagina niet gevonden</h3>
      </Col>
    )
    if (width <= 600){
      backgroundImage = data.SmallBackgroundImage;
    }
  }

  return (
    <>
      <SEO title="404-page" />
      <Layout
        width={width}
        backgroundImage={backgroundImage.childImageSharp.fluid}
        coverText={coverText}
        bottomBannerBar={true}
        backgroundImageStyles={{ backgroundColor: "black", backgroundPosition: "right", height: "100vh" }}>
      </Layout>
    </>
  )
}

export default NotFoundPage
